import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country: "-1",
  region: {
    name: "-1",
    code: "-1",
  },
  regionDisabled: true,
  allActive: false,
};

export const allSlice = createSlice({
  name: "all",
  initialState,
  reducers: {
    setAllCountry: (state, action) => {
      state.country = action.payload;
    },
    setAllRegion: (state, action) => {
      state.region = action.payload;
    },
    setRegionList: (state, action) => {
      state.regionList = action.payload;
    },
    clearAllRegion: (state) => {
      state.region = initialState.region;
    },
    setAllActive: (state, action) => {
      state.allActive = action.payload;
    },
    setAllRegionDisabled: (state, action) => {
      state.regionDisabled = action.payload;
    },
    clearCountryRegion: (state) => {
      state.country = initialState.country;
      state.region = initialState.region;
      state.regionDisabled = initialState.regionDisabled;
    },
  },
});

export const {
  setAllCountry,
  setAllRegion,
  clearAllRegion,
  setAllActive,
  setAllRegionDisabled,
  clearCountryRegion,
} = allSlice.actions;

export const selectAllRegionDisabled = (state) => {
  return state.all.regionDisabled;
};

export const selectAllCountry = (state) => {
  return state.all.country;
};

export const selectAllRegion = (state) => {
  return state.all.region;
};

export const selectAllActive = (state) => {
  return state.all.allActive;
};

export default allSlice.reducer;
