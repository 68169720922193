/* eslint-disable react/style-prop-object */
import React from "react";
import { SBox, SRow, SCol, SBanner } from "@avalara/skylab-react";
import "./error.css";

const ErrorPage = (props) => {
  let dom = null;
  if (props.IsAccountExpired) {
    dom = (
      <div className="flex dl-flex-fill-height dl-scroll dl-accountExpired-background">
        <SBanner id="errorBanner" status="error" nodismiss={true}>
          <span>
            The subscription for this account has expired. The customer can't
            access AvaTax without restoring their subscription
          </span>
        </SBanner>
      </div>
    );
  } else {
    dom = (
      <div className="flex dl-flex-fill-height dl-scroll dl-error-background">
        <SRow>
          <SCol className="center margin-top-md">
            <SBox>
              <div className="pad-all-lg">
                <h2 className="margin-all-none font-bold">
                  It's not you, it's us
                </h2>
                <p className="text-md">We're having connection problem.</p>
                <p className="text-md">Wait a few minutes and try again.</p>
                <p>
                  If problem persists, contact
                  <a
                    className="pad-left-sm"
                    href="https://www.avalara.com/us/en/about/support.html"
                  >
                    Avalara Support
                  </a>
                </p>
              </div>
            </SBox>
          </SCol>
        </SRow>
      </div>
    );
  }
  return <React.Fragment>{dom}</React.Fragment>;
};

export default ErrorPage;
