import React from "react";
import axios from "axios";

import { logger } from "../logger";
import axiosInstance from "../../axios";
import ErrorPage from "./ErrorPage";

window.addEventListener("error", async (event) => {
  const params = {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno,
  };
  await logger(2, 4, params);
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isAccountExpired: false };
  }

  errorHandler = async (error) => {
    const params = error.request
      ? {
          method: error.config.method,
          url: error.config.url,
          status: error.request.status,
          statusText: error.request.statusText,
        }
      : error.response
      ? {
          method: error.config.method,
          url: error.config.url,
          status: error.response.status,
          statusText: error.response.statusText,
        }
      : {};
    await logger(1, 4, params);
    return;
  };

  componentDidMount() {
    // Set axios interceptors
    this.requestInterceptor = axiosInstance.interceptors.request.use(
      (req) => {
        this.setState({ hasError: false });
        return req;
      },
      async (error) => {
        this.setState({ hasError: true });
        await this.errorHandler(error);
        return Promise.reject(error);
      }
    );

    this.responseInterceptor = axiosInstance.interceptors.response.use(
      async (res) => {
        const params = {
          method: res.config.method,
          url: res.config.url,
          status: res.status,
          statusText: res.statusText,
        };
        await logger(1, 2, params);
        return res;
      },
      async (error) => {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 403 || error.response.status === 401)
        ) {
          await axios
            .get(`//${process.env.REACT_APP_API_HOST}/session`, {
              withCredentials: true,
            })
            .then(async (sessionResponse) => {
              if (
                !(sessionResponse.data && sessionResponse.data.isAuthenticated)
              ) {
                window.location = `//${process.env.REACT_APP_API_HOST}/auth/login?redirectUrl=${window.location.href}`;
              } else {
                this.setState({ hasError: true });
                await this.errorHandler(error);
              }
            })
            .catch(async (error) => {
              if (
                error.response.data.Message.includes("AccountExpiredException")
              ) {
                this.setState({ hasError: true, isAccountExpired: true });
                await this.errorHandler(error);
              } else {
                this.setState({ hasError: true });
                await this.errorHandler(error);
              }
            });
        } else {
          this.setState({ hasError: true });
          await this.errorHandler(error);
        }
        return Promise.reject(error);
      }
    );
  }

  componentWillUnmount() {
    // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
    axiosInstance.interceptors.request.eject(this.requestInterceptor);
    axiosInstance.interceptors.response.eject(this.responseInterceptor);
  }

  static getDerivedStateFromError(error) {
    // It will update the state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // It will catch error in any component below.
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this?.state?.hasError) {
      return (
        <React.Fragment>
          <ErrorPage IsAccountExpired={this?.state?.isAccountExpired} />
        </React.Fragment>
      );
    }
    return this?.props?.children;
  }
}

export default ErrorBoundary;
