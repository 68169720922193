import { useSelector } from "react-redux";
import { selectSession } from "../store/sessionSlice";

export function getCurrencyFormater() {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return currencyFormatter;
}

export function updateSelectedAttribute(array, value) {
  return array.map((x) => {
    const selectedValue = x.value === value ? true : false;
    return { ...x, selected: selectedValue };
  });
}

export function getAvataxSubscription() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const session = useSelector(selectSession);
  return session.subscriptions.filter((subs) => subs === "AvaTaxPro").length > 0
    ? true
    : false;
}

export const getRowKey = (row) => {
  return row.id;
};

export function getDateFormater() {
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return dateFormatter;
}

export function getDateTimeFormater() {
  const dateTimeFormatter = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
  return dateTimeFormatter;
}

export function validateDate(
  fromDateSelector,
  toDateSelector,
  isSubmit = false
) {
  let fromDate = document.querySelector(
    `s-datepicker[inputid=${fromDateSelector}]`
  );

  let toDate = document.querySelector(
    `s-datepicker[inputid=${toDateSelector}]`
  );

  if (isSubmit && fromDate && toDate) {
    if (Date.parse(fromDate.value) > Date.parse(toDate.value)) {
      fromDate.className = "";
      toDate.className = "error";
      return true;
    }
    return false;
  }

  if (
    fromDate &&
    toDate &&
    Date.parse(fromDate.value) < Date.parse(toDate.value)
  ) {
    fromDate.className = "";
    toDate.className = "";
    return true;
  }
  return false;
}

export function escapeSpecialChars(str) {
  return encodeURIComponent(
    str
      .replace(/[\\]/g, "\\\\")
      .replace(/[\"]/g, '\\"') //eslint-disable-line no-useless-escape
      .replace(/[\/]/g, "\\/") //eslint-disable-line no-useless-escape
      .replace(/[\b]/g, "\\b")
      .replace(/[\f]/g, "\\f")
      .replace(/[\n]/g, "\\n")
      .replace(/[\r]/g, "\\r")
      .replace(/[\t]/g, "\\t")
      .replace(/'/g, "''")
      .replace(/[#]/g, "%23")
      .trim()
  );
}

export function getOrderByQueryString(sortColumn) {
  let [orderByParam, order] = sortColumn;
  order = order ? "asc" : "desc";
  const orderByQueryString = `$orderBy=${orderByParam} ${order}`;
  return orderByQueryString;
}

export function getPaginateQueryString(page, paginateDetails) {
  let rowsPerPage = page.paginate.rowsPerPage;
  let endIndex = 9;
  let startIndex = 0;
  let currentPage = 1;
  if (paginateDetails) {
    rowsPerPage = paginateDetails.rowsPerPage
      ? paginateDetails.rowsPerPage
      : rowsPerPage;
    endIndex = paginateDetails.endIndex ? paginateDetails.endIndex : endIndex;
    startIndex = paginateDetails.startIndex
      ? paginateDetails.startIndex
      : startIndex;
    currentPage = paginateDetails.currentPage
      ? paginateDetails.currentPage
      : currentPage;
  }
  const paginateQueryString = `$top=${rowsPerPage}&$skip=${startIndex}`;
  return [rowsPerPage, endIndex, startIndex, currentPage, paginateQueryString];
}

export function covertIntoCamelCase(str) {
  return str
    .split(" ")
    .map((word, index) =>
      index !== 0
        ? word[0].toUpperCase() + word.substr(1)
        : word[0].toLowerCase() + word.substr(1)
    )
    .join("");
}

export function FileSizeFormater(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return bytes;
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (i === 0) {
    return bytes + " " + sizes[i];
  }

  return Math.floor((bytes / Math.pow(1024, i)) * 100) / 100 + " " + sizes[i];
}
