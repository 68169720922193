import axios from "axios";
import store from "../store/store";

// type
//   axios logs = 1
//   DOM logs = 2

// LogLevels
//       Trace = 0
//       Debug = 1
//       Information = 2
//       Warning = 3
//       Error = 4
//       Critical = 5
//       None = 6

// LogSource
//       WebUI = 0

export const logger = async (type, logLevel, params) => {
  let logMsg = "";
  const session = store.getState().session;
  if (!session.isAuthenticated) {
    return;
  }

  if (type === 1) {
    logMsg = `API:${params.method} | Url:${params.url} | Status-Code:${params.status} | StatusText:${params.statusText}`;
  }

  if (type === 2) {
    logMsg = `DOM:${params.message} |at filename:${params.filename} | lineno:${params.lineno} | colno:${params.colno}`;
  }

  await axios.post(
    `//${process.env.REACT_APP_API_HOST}/logger`,
    { logLevel: logLevel, logSource: 0, logMessage: logMsg },
    {
      withCredentials: true,
    }
  );
};

export default logger;
