function toast() {
  function showToast(status, text, id, actionText, actionHandler, details) {
    document.querySelector("s-toasts")?.createToast({
      status,
      text,
      id,
      actionText,
      actionHandler,
      details,
    });
  }

  function hideAllToast() {
    document.querySelector("s-toasts")?.dismissAllToasts();
  }

  function hideToast(toastID) {
    document.querySelector("s-toasts")?.dismissToast(toastID);
  }

  return [showToast, hideToast, hideAllToast];
}

export default toast;
