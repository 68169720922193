export const URLToPageNameMap = {
  "/search": "search-all-transactions",
  "/search/all": "search-all-transactions",
  "/search/all/transactions": "search-all-transactions",
  "/search/all/certificates": "search-all-certificates",
  "/search/all/returns": "search-all-returns",
  "/search/transactions": "search-transactions",
  "/search/certificates": "search-certificates",
  "/search/returns": "search-returns",
  // "/requestTransactions": "requestTransactions",
  "/history": "viewHistory",
  "/archivedTransactionReports": "archivedTransactionReports",
};
