import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";

const RecordCount = React.memo((props) => {
  return (
    <React.Fragment>
      <SRow className="margin-right-none margin-top-sm">
        <SCol className="pad-bottom-none flex justify-content-end">
          <div>
            {props.recordCount} {props.recordName}
          </div>
        </SCol>
      </SRow>
    </React.Fragment>
  );
});

export default RecordCount;
