import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

export const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isInternalUser: false,
  accountId: null,
  userId: null,
  role: null,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  companyIds: [],
  activeCompany: {
    id: null,
    name: null,
  },
  subscriptions: [],
  environment: null,
  accessToken: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setData: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isInternalUser = action.payload.isInternalUser;
      state.accountId = action.payload.accountId;
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.companyIds = action.payload.companyIds;
      state.subscriptions = action.payload.subscriptions;
      state.environment = action.payload.environment;
      state.accessToken = action.payload.accessToken;

      if (action.payload.activeCompany) {
        state.activeCompany.id = action.payload.activeCompany.id;
        state.activeCompany.name = action.payload.activeCompany.name;
      }
    },
  },
});

export const { setLoading, setData } = sessionSlice.actions;

export const fetchSessionAsync = () => async (dispatch, getState) => {
  const session = getState().session;

  if (session.isAuthenticated || session.isLoading) {
    return;
  }

  dispatch(setLoading(true));
  const response = await axios.get(
    `//${process.env.REACT_APP_API_HOST}/session`,
    { withCredentials: true }
  );
  dispatch(setData(response.data));

  if (!response.data.isAuthenticated) {
    window.location = `//${process.env.REACT_APP_API_HOST}/auth/login?redirectUrl=${window.location.href}`;
  } else {
    dispatch(setLoading(false));
  }
};

export const selectSession = (state) => state.session;

// Data for the Skylab header WebComponent.
// SEE: https://skylabsdk.avalara.io/layout/header
export const selectSkylabHeader = (state) => {
  const user = JSON.stringify(
    {
      accountId: state.session.accountId,
      givenName: state.session.firstName,
      familyName: state.session.lastName,
      email: state.session.email,
      isLoggedIn: state.session.isAuthenticated,
      isTechSupport: state.session.isInternalUser,
    },
    null,
    2
  );

  return {
    user,
    productName: "",
    env: process.env.REACT_APP_CUP_ENV,
    appKey: "documentlocker",
    homeUrl: window.location.origin,
    loginUrl: `https://${process.env.REACT_APP_CUP_HOST}`,
    logoutUrl: `//${process.env.REACT_APP_API_HOST}/auth/logout`,
    changePasswordUrl: `https://${process.env.REACT_APP_IDENTITY_HOST}/changePassword`,
    switchCompanyUrl: `https://${process.env.REACT_APP_CUP_HOST}/cup/a/${state.session.accountId}/c/${state.session.activeCompany.id}/companies/select`,
  };
};

export default sessionSlice.reducer;
