import React from "react";
import { STag } from "@avalara/skylab-react";

export default function CLockerFileStatusTag(lockerFile) {
  let tagColor = "yellow-medium";
  if (lockerFile.fileStatus === "Completed") tagColor = "green-light";
  if (lockerFile.fileStatus === "Processing") tagColor = "blue-light";

  return <STag color={tagColor}> {lockerFile.fileStatus} </STag>;
}
