import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./sessionSlice";
import lockerReducer from "./lockerSlice";
import uploadReducer from "./uploadSlice";
import allReducer from "./allSlice";

export default configureStore({
  reducer: {
    session: sessionReducer,
    locker: lockerReducer,
    upload: uploadReducer,
    all: allReducer,
  },
});
