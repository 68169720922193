import React from "react";
import { useSelector } from "react-redux";

import { selectNoDataType, selectIsLoadingFiles } from "./index";

const NoData = React.memo((props) => {
  const noDataType = useSelector(
    selectNoDataType(props?.locker?.lockerDefinitionId)
  );
  const isLoading = useSelector(
    selectIsLoadingFiles(props?.locker?.lockerDefinitionId)
  );

  let noDataMsg = null;
  if (isLoading === false && noDataType === "onLanding") {
    noDataMsg = (
      <React.Fragment>
        <div className="margin-top-md">
          <div className="font-bold text-md">
            You don't have any available downloads
          </div>
        </div>
      </React.Fragment>
    );
  } else noDataMsg = null;

  return <React.Fragment>{noDataMsg}</React.Fragment>;
});

export default NoData;
