import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { getPaginateQueryString, getOrderByQueryString } from "../shared/utils";
import { fetchLockerSizeAsync } from "../store/uploadSlice";

const innerLockerSlice = {
  lockerInstanceIds: [],
  fileSlice: {
    isLoadingFiles: false,
    files: [],
    noDataType: "",
    page: {
      data: [],
      paginate: {
        currentPage: 1,
        startIndex: 0,
        endIndex: 9,
        rowsPerPage: 10,
        totalRecords: 0,
      },
    },
    sortColumn: ["created", false],
  },
  isUploadDialogOpen: false,
  selectedColumns: [
    { id: "category", label: "Category", hidden: false },
    { id: "title", label: "Title", hidden: false },
    { id: "displayFileName", label: "File Name", hidden: false },
    { id: "displayFileSize", label: "Size", hidden: false },
    { id: "createdBy", label: "Created by", hidden: false },
    { id: "availableUntil", label: "Available until", hidden: false },
    { id: "description", label: "Description", hidden: true },
    { id: "fileStatus", label: "Status", hidden: false },
    { id: "downloaded", label: "Downloaded", hidden: true },
    { id: "downloadedBy", label: "Downloaded by", hidden: true },
    { id: "owner", label: "Owner", hidden: true },
  ],
  isDownloadFile: false,
};

const locDefIdsToIndex = {};

const idToIndexMapper = (id) => {
  return locDefIdsToIndex[id];
};

export const lockerSlice = createSlice({
  name: "locker",
  initialState: [],
  reducers: {
    setLockerDefinitions: (state, action) => {
      return action.payload;
    },
    setIsLoadingFiles: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].fileSlice.isLoadingFiles = value;
    },
    setLockerInstancesIds: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].lockerInstanceIds = value;
    },
    setNoDataTypeOnLand: (state, action) => {
      const { id } = action.payload;
      state[idToIndexMapper(id)].fileSlice.noDataType = "onLanding";
    },
    setNoDataTypeToNull: (state, action) => {
      const { id } = action.payload;
      state[idToIndexMapper(id)].fileSlice.noDataType =
        innerLockerSlice.fileSlice.noDataType;
    },
    setPage: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].fileSlice.page = value;
    },
    setPageToNull: (state, action) => {
      const { id } = action.payload;
      state[idToIndexMapper(id)].fileSlice.page =
        innerLockerSlice.fileSlice.page;
    },
    setSortColumn: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].fileSlice.sortColumn = value;
    },
    setIsUploadDialogOpen: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].isUploadDialogOpen = value;
    },
    setIsDownloadFile: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].isDownloadFile = value;
    },
    setSelectedColumns: (state, action) => {
      const { id, value } = action.payload;
      state[idToIndexMapper(id)].selectedColumns = value;
      if (localStorage.getItem("lockerColumns"))
        localStorage.removeItem("lockerColumns");
      localStorage.setItem(
        "lockerColumns",
        JSON.stringify(state[idToIndexMapper(id)].selectedColumns)
      );
    },
  },
});

export const {
  setIsLoadingFiles,
  setLockerInstancesIds,
  setNoDataTypeOnLand,
  setNoDataTypeToNull,
  setPage,
  setPageToNull,
  setSortColumn,
  setIsUploadDialogOpen,
  setLockerDefinitions,
  setSelectedColumns,
  setIsDownloadFile,
} = lockerSlice.actions;

export const selectLockerDefinitions = (state) => {
  return state.locker;
};

export const selectLockerDefinitionIds = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].lockerDefinitionId;
};

export const selectLockerInstancesIds = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].lockerInstanceIds;
};

export const selectIsLoadingFiles = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].fileSlice.isLoadingFiles;
};

export const selectNoDataType = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].fileSlice.noDataType;
};

export const selectPage = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].fileSlice.page;
};

export const selectSortColumn = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].fileSlice.sortColumn;
};

export const selectIsUploadDialogOpen = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].isUploadDialogOpen;
};

export const selectIsDownloadFile = (id) => (state) => {
  return state.locker[idToIndexMapper(id)].isDownloadFile;
};

export const selectSelectedColumns = (id) => (state) => {
  if (localStorage.getItem("lockerColumns")) {
    const config = localStorage.getItem("lockerColumns");
    if (config != null && config !== "null" && config.includes("{")) {
      return JSON.parse(localStorage.getItem("lockerColumns"));
    }
  }
  return state.locker[idToIndexMapper(id)].selectedColumns;
};
export const fetchLockerDefinitionsAsync = () => async (dispatch, getState) => {
  const existinglockers = getState().locker;
  if (existinglockers.length > 0) {
    return;
  }
  const response = await axios.get(
    `//${process.env.REACT_APP_API_HOST}/lockerdefinitions`,
    { withCredentials: true }
  );
  let lockers = [];
  if (response && response.data && response.data.value) {
    response.data.value.forEach((locker, index) => {
      locDefIdsToIndex[locker.lockerDefinitionId] = index;
      lockers.push({ ...locker, ...innerLockerSlice });
    });
  }
  dispatch(setLockerDefinitions(lockers));
};

export const fetchLockerInstancesAsync = (id) => async (dispatch, getState) => {
  const store = getState();
  const locker = store.locker[locDefIdsToIndex[id]];
  const session = store.session;

  if (locker.lockerInstanceIds.length > 0) {
    return;
  }
  const response = await axios.get(
    `//${process.env.REACT_APP_API_HOST}/companies/${session.activeCompany.id}/lockerinstances`,
    { withCredentials: true }
  );
  if (response && response.data && response.data.value) {
    let lockerDefinitionToInstanceIdMap = {};
    response.data.value.forEach((instance) => {
      if (lockerDefinitionToInstanceIdMap[instance.lockerDefinitionId]) {
        lockerDefinitionToInstanceIdMap[instance.lockerDefinitionId].push(
          instance.lockerInstanceId
        );
      } else {
        lockerDefinitionToInstanceIdMap[instance.lockerDefinitionId] = [
          instance.lockerInstanceId,
        ];
      }
    });
    for (let locdefID in lockerDefinitionToInstanceIdMap) {
      dispatch(
        setLockerInstancesIds({
          id: locdefID,
          value: lockerDefinitionToInstanceIdMap[locdefID],
        })
      );
    }
  }
};

export const fetchLockerFilesAsync =
  (id, onLanding, syncAfterActionTriggered, paginateDetails) =>
  async (dispatch, getState) => {
    let locker = getState().locker[locDefIdsToIndex[id]];
    if (!syncAfterActionTriggered && locker.fileSlice.isLoadingFiles) return;

    onLanding && dispatch(setIsLoadingFiles({ id: id, value: true }));
    await dispatch(fetchLockerInstancesAsync(id));

    const updatedStore = getState();
    const session = updatedStore.session;
    locker = updatedStore.locker[locDefIdsToIndex[id]];

    const sortColumn = locker.fileSlice.sortColumn;
    const page = locker.fileSlice.page;

    const orderByQueryString = getOrderByQueryString(sortColumn);

    const [
      rowsPerPage,
      endIndex,
      startIndex,
      currentPage,
      paginateQueryString,
    ] = getPaginateQueryString(page, paginateDetails);

    let combinedQueryString = `?${orderByQueryString}&${paginateQueryString}`;
    const files = [];
    let paginateData = innerLockerSlice.fileSlice.page.paginate;
    for (let index = 0; index < locker.lockerInstanceIds.length; index++) {
      const instanceId = locker.lockerInstanceIds[index];
      const filesResponse = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/companies/${session.activeCompany.id}/lockerinstances/${instanceId}/files${combinedQueryString}`,
        { withCredentials: true }
      );
      if (filesResponse.data && filesResponse.data.value) {
        files.push(...filesResponse.data.value);
        dispatch(setNoDataTypeToNull({ id: id }));
        paginateData = {
          currentPage: currentPage,
          startIndex: startIndex,
          endIndex: endIndex,
          rowsPerPage: rowsPerPage,
          totalRecords: filesResponse.data.count,
        };
      }
    }
    dispatch(
      setPage({
        id: id,
        value: {
          data: files,
          paginate: paginateData,
        },
      })
    );
    onLanding && dispatch(setIsLoadingFiles({ id: id, value: false }));
    if (files.length === 0) dispatch(setNoDataTypeOnLand({ id: id }));
  };

export const downloadLockerFileAsync =
  (row, id) => async (dispatch, getState) => {
    const session = getState().session;
    const isDownloadFile =
      getState().locker[idToIndexMapper(id)].isDownloadFile;

    if (isDownloadFile) {
      return;
    }

    dispatch(setIsDownloadFile({ id: id, value: true }));

    await axios
      .get(
        `//${process.env.REACT_APP_API_HOST}/companies/${session.activeCompany.id}/lockerinstances/${row.lockerInstanceId}/files/${row.lockerInstanceFileId}`,
        { withCredentials: true, responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row.displayFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      });
    dispatch(fetchLockerFilesAsync(id, false, true));
    dispatch(setIsDownloadFile({ id: id, value: false }));
  };

export const deleteLockerFileAsync =
  (row, id) => async (dispatch, getState) => {
    const session = getState().session;

    await axios.delete(
      `//${process.env.REACT_APP_API_HOST}/companies/${session.activeCompany.id}/lockerinstances/${row.lockerInstanceId}/files/${row.lockerInstanceFileId}`,
      { withCredentials: true }
    );
    dispatch(fetchLockerFilesAsync(id, false, true));
    dispatch(fetchLockerSizeAsync(id));
  };

export default lockerSlice.reducer;
