import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SIcon,
  SPagination,
  SLoader,
  SRow,
  SCol,
  SProgress,
  SRowActions,
  SDialog,
} from "@avalara/skylab-react";

import {
  fetchLockerFilesAsync,
  downloadLockerFileAsync,
  deleteLockerFileAsync,
  selectSession,
  DataGrid,
  gridHeader,
  getDateFormater,
  RecordCount,
  selectPage,
  selectSortColumn,
  setSortColumn,
  selectlockerSize,
  fetchLockerSizeAsync,
  selectIsWarning,
  setIsWarning,
  FileSizeFormater,
  selectSelectedColumns,
  getDateTimeFormater,
  selectIsDownloadFile,
  useId,
} from "./index";
import LockerColumnsManager from "./LockerColumnsManager";
import LockerFileStatusTag from "./LockerFileStatusTag";
export default function LockerPageGrid(props) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [rowId, setRowId] = useState(0);
  const session = useSelector(selectSession, shallowEqual);
  const isTechSupport = session.isInternalUser;
  const lockerDefinitionId = props.locker.lockerDefinitionId;
  const enableDelete = props.locker.enableDelete;
  const enableDownload = props.locker.enableDownload;
  const enableUpload = props.locker.enableUpload;
  const daysToPurge = props.locker.daysToPurge;

  const page = useSelector(selectPage(lockerDefinitionId), shallowEqual);
  const pageData = page.data;
  const paginateData = page.paginate;
  const sortColumn = useSelector(selectSortColumn(lockerDefinitionId));

  const lockerMaxSize = props.locker.maxSize;
  const lockerCurrentSize = useSelector(selectlockerSize);
  const lockerSize =
    Math.floor((lockerCurrentSize / lockerMaxSize) * 100 * 100) / 100;
  const remainingSize = lockerMaxSize - lockerCurrentSize;
  const iswarning = useSelector(selectIsWarning);
  const storgeMsg =
    remainingSize !== 0
      ? `${FileSizeFormater(remainingSize)} free out of ${FileSizeFormater(
          lockerMaxSize
        )} `
      : null;
  const [getCurrentId, getNextId] = useId();

  const setWarning = useCallback(() => {
    let displayTextDiv = document.querySelector(
      's-progress div[ref="loading-text"]'
    );
    if (displayTextDiv) {
      iswarning
        ? (displayTextDiv.className = "text-red-medium")
        : displayTextDiv.classList.remove("text-red-medium");
    }
  }, [iswarning]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [row, setRow] = useState({});
  const selectedColumns = useSelector(
    selectSelectedColumns(lockerDefinitionId)
  );

  const isDownloadFile = useSelector(selectIsDownloadFile(lockerDefinitionId));

  useEffect(() => {
    dispatch(fetchLockerSizeAsync(lockerDefinitionId)); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    lockerSize >= 100
      ? dispatch(setIsWarning(true))
      : dispatch(setIsWarning(false));
    setWarning();
  }, [lockerSize, setWarning, dispatch]);

  function downloadFileAsync(row, id) {
    return function () {
      setRowId(row.lockerInstanceFileId);

      if (session.activeCompany.id !== null)
        dispatch(downloadLockerFileAsync(row, id));
    };
  }
  function deleteFileAsync(row, id) {
    if (session.activeCompany.id !== null)
      dispatch(deleteLockerFileAsync(row, id));
  }

  function sortFunction(id) {
    return async function (e) {
      setSubmitting(true);
      const columnId = e.detail.id;
      const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
      dispatch(
        setSortColumn({
          id: id,
          value: [columnId, order],
        })
      );
      await dispatch(fetchLockerFilesAsync(id));
      setSubmitting(false);
      setWarning();
    };
  }
  const onDialogCancelClick = () => {
    setIsDialogOpen(false);
  };
  const onDeleteFileClick = (row) => {
    setIsDialogOpen(true);
    setRow(row);
  };
  const onDialogDeleteClick = () => {
    setIsDialogOpen(false);
    deleteFileAsync(row, lockerDefinitionId);
  };

  const columns = [
    gridHeader(
      "Created",
      "created",
      (value) => getDateFormater().format(new Date(value)),
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "created"
    ),
    gridHeader(
      "Category",
      "category",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "category"
    ),
    gridHeader(
      "Title",
      "title",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "title"
    ),
    gridHeader(
      "File Name",
      "displayFileName",
      (value, row) => {
        return <div>{value}</div>;
      },
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "displayFileName"
    ),
    gridHeader(
      "Size",
      "displayFileSize",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "displayFileSize"
    ),

    gridHeader(
      "Created by",
      "createdBy",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "createdBy"
    ),
    gridHeader(
      "Description",
      "description",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "description"
    ),
    gridHeader(
      "Status",
      "fileStatus",
      (value, row) => (
        <React.Fragment>
          <span style={{ minWidth: "10%" }}>{LockerFileStatusTag(row)}</span>
        </React.Fragment>
      ),
      null,
      null,
      null,
      null,
      null,
      "fileStatus"
    ),
    gridHeader(
      "Downloaded",
      "downloaded",
      (value) => {
        if (value) {
          return getDateTimeFormater().format(new Date(value));
        }
      },
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "downloaded"
    ),
    gridHeader(
      "Downloaded by",
      "downloadedBy",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "downloadedBy"
    ),
    gridHeader(
      "Owner",
      "owner",
      null,
      null,
      "text-row-top",
      null,
      null,
      sortFunction(lockerDefinitionId),
      "owner"
    ),
    gridHeader(
      "Available until",
      "created",
      (value) => {
        let d = new Date(value);
        if (daysToPurge > 0) {
          d.setDate(d.getDate() + daysToPurge);
          return getDateFormater().format(d);
        } else {
          return "";
        }
      },
      null,
      "text-row-top",
      null,
      null,
      null,
      "availableUntil"
    ),

    gridHeader("", "", (value, row) => {
      return (isTechSupport || enableDownload) &&
        row.fileStatus === "Completed" ? (
        <button
          onClick={downloadFileAsync(row, lockerDefinitionId)}
          className={`${
            isDownloadFile && row.lockerInstanceFileId === rowId
              ? "link margin-right-xs loading"
              : "link margin-right-xs"
          }`}
          style={{ color: "black" }}
          aria-label={`${row.id}-download`}
        >
          <SIcon
            title="Download"
            name="download"
            role="img"
            aria-label="download"
            aria-hidden="true"
          />
        </button>
      ) : null;
    }),

    gridHeader("", "", (value, row) => {
      return (
        <>
          {(isTechSupport || enableDelete) &&
          (row.fileStatus === "Completed" ||
            row.fileStatus === "Import Failed" ||
            row.fileStatus === "Locker size exceeded" ||
            row.fileStatus === "File extension not allowed") ? (
            <React.Fragment>
              <span htmlFor={getNextId()} />
              <SRowActions
                menuId={`menu-${getCurrentId()}`}
                actionItems={[
                  {
                    label: "Delete",
                    classList: ["primary", "icon-leading"],
                    id: `menu-${getCurrentId}-delete`,
                  },
                ]}
                collapsed
                onS-select={(e) => {
                  onDeleteFileClick(row);
                }}
              />
            </React.Fragment>
          ) : null}
        </>
      );
    }),
  ];

  function getRowKey(row) {
    return row.lockerInstanceFileId;
  }

  async function handlePaginate(e) {
    if (
      e.detail.currentPage !== 0 &&
      paginateData.totalRecords > 0 &&
      e.detail.flag.length > 0
    ) {
      setSubmitting(true);
      await dispatch(
        fetchLockerFilesAsync(lockerDefinitionId, false, "", e.detail)
      );
      setSubmitting(false);
    }
  }
  let modifiedColumns = [];

  const arrangeColumns = (columnlist) => {
    const visibleColumns = columnlist.filter((a) => a.hidden === false);
    const tempCol = [modifiedColumns[0]];
    for (let j = 0; j < visibleColumns.length; ) {
      const item = modifiedColumns.find((obj) => {
        return obj.id === visibleColumns[j].id;
      });
      tempCol.push(item);
      j += 1;
    }
    modifiedColumns = tempCol;
  };

  const ManageColumns = () => {
    modifiedColumns = columns;
    let downloadCol = modifiedColumns[modifiedColumns.length - 2];
    let deleteCol = modifiedColumns[modifiedColumns.length - 1];
    selectedColumns.forEach((col) => {
      if (col.hidden) {
        modifiedColumns = modifiedColumns.filter((obj) => {
          return obj.id !== col.id;
        });
      }
    });
    arrangeColumns(selectedColumns);
    modifiedColumns.push(downloadCol);
    modifiedColumns.push(deleteCol);
    return modifiedColumns;
  };

  let dom = null;

  if (paginateData.totalRecords > 0) {
    dom = submitting ? (
      <React.Fragment>
        <div className="flex dl-flex-fill-height dl-flex-center">
          <h3>Loading ...</h3>
          <SLoader id="page-loader" class="medium" aria-live="polite" loading />
        </div>
      </React.Fragment>
    ) : (
      <>
        {(enableUpload || isTechSupport) && (
          <SRow>
            <SCol span="auto" className="pad-top-sm">
              <SProgress
                displayText={
                  iswarning
                    ? `${lockerSize}% loaded Delete existing files to make some space for new file`
                    : `${lockerSize}% loaded`
                }
                valueNow={lockerSize}
              />
              {storgeMsg && <div>{storgeMsg}</div>}
            </SCol>
          </SRow>
        )}
        <SDialog
          open={isDialogOpen}
          id="delete-dialog"
          onS-dismiss={(e) => onDialogCancelClick()}
          aria-modal="true"
          role="alertdialog"
        >
          <div slot="header" id="dialog-title">
            Delete this file?
          </div>
          <div slot="body">This can't be undone.</div>
          <div slot="footer">
            <button
              className="secondary small"
              onClick={() => onDialogCancelClick()}
            >
              Cancel
            </button>
            <button
              className="primary small"
              onClick={() => onDialogDeleteClick()}
            >
              Delete
            </button>
          </div>
        </SDialog>
        <LockerColumnsManager locker={props.locker}></LockerColumnsManager>
        <RecordCount
          recordCount={paginateData.totalRecords}
          recordName={"files"}
        />
        <s-table-container class="margin-top-md">
          <DataGrid
            columns={ManageColumns(selectedColumns)}
            rows={pageData}
            getKey={getRowKey}
            sortColumn={sortColumn}
            inContainer
            loading={submitting}
          />
        </s-table-container>
      </>
    );
  }
  return (
    <>
      {dom}
      {paginateData.totalRecords > 0 ? (
        <SPagination
          className="margin-top-md"
          rowsPerPage={paginateData.rowsPerPage}
          totalRecords={paginateData.totalRecords}
          startIndex={paginateData.startIndex}
          onS-paginate={(e) => {
            handlePaginate(e);
          }}
        ></SPagination>
      ) : null}
    </>
  );
}
