import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SColumnManager } from "@avalara/skylab-react";
import {
  setSelectedColumns,
  selectSelectedColumns,
} from "../../../store/lockerSlice";

const LockerColumnsManager = React.memo((props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const defaultColumns = useSelector(
    selectSelectedColumns(props.locker.lockerDefinitionId)
  );
  const applyChanges = async (columnItem) => {
    setSubmitting(true);
    dispatch(
      setSelectedColumns({
        id: props.locker.lockerDefinitionId,
        value: columnItem,
      })
    );
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <SColumnManager
        id="locker-column-manager"
        columnItems={defaultColumns}
        loading={submitting}
        onS-save={(e) => applyChanges(e.detail.columnItems)}
      />
    </React.Fragment>
  );
});

export default LockerColumnsManager;
