import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { SRow, SCol } from "@avalara/skylab-react";
import { selectSession, selectSkylabHeader } from "../../store/sessionSlice";
import { URLToPageNameMap } from "../../shared/constants";
import LockerPage from "../lockers/lockerPage/LockerPage";
import {
  selectLockerDefinitions,
  fetchLockerDefinitionsAsync,
} from "../../store/lockerSlice";
import { covertIntoCamelCase } from "../../shared/utils";
import ErrorPage from "./../../shared/Error/ErrorPage";

function Navigation() {
  const dispatch = useDispatch();
  const session = useSelector(selectSession, shallowEqual);
  const skylabHeader = useSelector(selectSkylabHeader, shallowEqual);
  const lockers = useSelector(selectLockerDefinitions);

  function getRowKey(locker) {
    return locker.lockerDefinitionId;
  }
  useEffect(() => {
    if (session.isAuthenticated) {
      dispatch(fetchLockerDefinitionsAsync());
    }
  }, [session.isAuthenticated, dispatch]);

  const routesDOM = lockers.map((locker) => {
    const lockerName = covertIntoCamelCase(locker.name);
    URLToPageNameMap[`/myLockers/${lockerName}`] = `myLockers-${lockerName}`;
    return (
      <Route
        path={`/myLockers/${lockerName}`}
        exact
        key={getRowKey(locker)}
        element={<LockerPage locker={locker} />}
      />
    );
  });

  return (
    <React.Fragment>
      <SRow>
        <SCol className="pad-bottom-none">
          <h4 className="margin-top-none margin-bottom-none">
            <span className="dl-product-name margin-right-xs">
              Document Central
            </span>
            <s-pipe class="margin-right-xs margin-left-none" />
            <span className="margin-right-sm text-uppercase dl-brand-gray">
              {session.activeCompany.name}
            </span>
            <a href={skylabHeader.switchCompanyUrl}>Switch Company</a>
          </h4>
        </SCol>
      </SRow>
      <div className="flex dl-flex-fill-height">
        <Routes>
          <Route path="/myLockers">{routesDOM} </Route>
          <Route
            path="/"
            exact
            element={<Navigate to="/myLockers/downloads" />}
          />
          {routesDOM !== null && routesDOM.length > 0 ? (
            <Route path="*" element={<ErrorPage></ErrorPage>} />
          ) : null}
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default React.memo(Navigation);
