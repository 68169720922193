import React from "react";
import { createRoot } from "react-dom/client";
import store from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Application from "./features/application/Application";
import { HelmetProvider } from "react-helmet-async";
import "./base.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Application />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
serviceWorker.unregister();
