import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SCol, SRow } from "@avalara/skylab-react";

import {
  LockerPageNoData,
  LockerPageGrid,
  selectIsLoadingFiles,
  fetchLockerFilesAsync,
  selectSession,
  LockerSubSection,
  UploadDocuments,
  selectIsUploadDialogOpen,
} from "./index";

export default function LockerPage(props) {
  const dispatch = useDispatch();
  const session = useSelector(selectSession, shallowEqual);
  const lockerDefinitionId = props?.locker?.lockerDefinitionId;
  const isLoadingFiles = useSelector(selectIsLoadingFiles(lockerDefinitionId));
  const isUploadDialogOpen = useSelector(selectIsUploadDialogOpen);
  useEffect(() => {
    if (session.activeCompany.id !== null && lockerDefinitionId) {
      dispatch(fetchLockerFilesAsync(lockerDefinitionId, true));
    }
  }, [session, dispatch, lockerDefinitionId]);

  let dom = null;

  let uploadDocDom = isUploadDialogOpen ? (
    <UploadDocuments locker={props?.locker} />
  ) : null;

  if (isLoadingFiles) {
    dom = (
      <>
        <div className="flex dl-flex-fill-height dl-flex-center">
          <h1>Loading ...</h1>
          <SLoader id="page-loader" class="large" aria-live="polite" loading />
        </div>
      </>
    );
  } else {
    dom = (
      <>
        <div className="margin-bottom-md">
          <SRow>
            <SCol className="pad-bottom-none text-xl">
              {props?.locker?.name}
            </SCol>
          </SRow>
          {props?.locker?.description ? (
            <SRow>
              <SCol className="pad-bottom-none font-semibold">
                {props?.locker?.description}
              </SCol>
            </SRow>
          ) : null}
        </div>
        <LockerSubSection locker={props?.locker} />
        <LockerPageGrid locker={props?.locker} />
        <LockerPageNoData locker={props?.locker} />
        {uploadDocDom}
      </>
    );
  }
  return <React.Fragment>{dom}</React.Fragment>;
}
